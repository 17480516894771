import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import { getToken } from "../../helpers/common";

const getCampaignJourneyLogs = async (payload) => {
  try {
    let token = getToken();
    console.log("data", payload);
    let result = await apiService.post(
      API_URL.CAMPAIGN_JOURNEY_LOGS,
      payload,
      token
    );

    return result;
  } catch (e) {
    console.log("Having error in get campaign log", e);
    return false;
  }
};
const getCampaignJourneyLogsFullData = async (payload) => {
  try {
    let token = getToken();
    console.log("data", payload);
    let result = await apiService.post(
      API_URL.CAMPAIGN_JOURNEY_LOGS_ALL_DATA,
      payload,
      token
    );
    console.log("result", result);
    return result;
  } catch (e) {
    console.log("Having error in get campaign log", e);
    return false;
  }
};
const journeyLogs = { getCampaignJourneyLogs, getCampaignJourneyLogsFullData };
export default journeyLogs;
