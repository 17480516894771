import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { saveAs } from "file-saver";
import { Table, Button, Modal } from "react-bootstrap";
import {
  getCampaignJourney,
  getCampaignJourneyLogsDetails,
} from "../../store/slices/campaignJourney/index";
import Layout from "../../layouts/default";
import DateFilter from "./DateFilter";

const CampaignReportPage = () => {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;

  const { campaignName, campaignType } = location.state || {};

  const campaignJourney = useSelector(
    (state) => state.campaignJourneySlice.campaignsJourney
  );

  console.log("campaignJourney-slice", campaignJourney);

  useEffect(() => {
    const fetchCampaignJourney = async () => {
      setLoading(true);
      const body = {
        id: campaignId,
        startLimit: (currentPage - 1) * dataPerPage, // Fix pagination calculation
        endLimit: dataPerPage,
      };

      try {
        dispatch(getCampaignJourney(body));
      } catch (error) {
        console.error("Error fetching campaign journey:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaignJourney();
  }, [dispatch, campaignId, currentPage]); // Ensure `currentPage` triggers re-fetch

  // Apply filter function
  const applyFilter = ({ from, to }) => {
    setLoading(true);
    const body = {
      id: campaignId,
      fromDate: from,
      toDate: to,
    };

    dispatch(getCampaignJourney(body)).finally(() => {
      setLoading(false);
    });
  };

  // Reset function
  const handleReset = () => {
    setLoading(true);
    setCurrentPage(1); // Reset to page 1
    dispatch(
      getCampaignJourney({
        id: campaignId,
        startLimit: 0,
        endLimit: dataPerPage,
      })
    ).finally(() => setLoading(false));
  };

  const handleNextPage = () => {
    if (campaignJourney.length === currentPage * dataPerPage) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };
  // Function to handle CSV export
  const exportToCSV = () => {
    const headers = [
      "S.N",
      "Campaign Type",
      "Trigger Date",
      "UUID",
      `Sent ${campaignType}`,
      `Failed ${campaignType}`,
      `Total ${campaignType} Count`,
    ];

    // Ensure proper CSV formatting
    const csvData = currentData.map((log, index) => [
      index + 1,
      log.channel?.name || "Email",
      log.latestTriggerDateTime || "",
      log._id || "",
      log.totalSuccessCount || 0,
      log.totalFailedCount || 0,
      log.totalTotalCount || 0,
    ]);

    // Convert to CSV format with proper escaping
    const csvString = [
      headers.map((header) => `"${header}"`).join(","), // Headers enclosed in quotes
      ...csvData.map((row) => row.map((value) => `"${value}"`).join(",")), // Values enclosed in quotes
    ].join("\r\n"); // Use Windows-compatible newline

    // Create a Blob and trigger download
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `campaign_report_${campaignId}.csv`);
  };

  // Pagination logic
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;

  let currentData = [];

  currentData = campaignJourney.slice(indexOfFirstData, indexOfLastData);
  console.log("Having error in indexOfLastData-", currentData);

  console.log("MY CURRENT DATA", currentData);
  const getDetailsOfCampaign = async (uuid) => {
    setLoading(true);
    const body = { uuid };

    try {
      const response = await dispatch(
        getCampaignJourneyLogsDetails(body)
      ).unwrap();
      setModalData(response);
      setShowModal(true);
      console.log("Fetched Campaign Journey Data:", response);
    } catch (error) {
      console.error(
        "Error fetching campaign journey:",
        error?.message || error
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div>
        {/* Date Filter and Reset Button */}
        <div className="d-flex align-items-center justify-content-between pt-2">
          <DateFilter applyFilter={applyFilter} resetFilter={handleReset} />

          <Button
            onClick={exportToCSV}
            disabled={currentData.length == 0}
            className="mb-3 ml-3"
            variant="primary"
          >
            Export
          </Button>
        </div>

        {/* Campaign Report Table */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.N</th>
              <th>Campaign Type</th>
              <th>Trigger Date(dd-mm-yy)</th>

              <th>UUID</th>
              <th>Sent {campaignType}</th>
              <th>Failed {campaignType}</th>
              <th>Total {campaignType} Count</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {currentData.length === 0 ? (
              <tr>
                <td colSpan="6" className="text-center">
                  No data available
                </td>
              </tr>
            ) : (
              currentData.map((log, index) => (
                <tr
                  // style={{
                  //   backgroundColor:
                  //     (log.from == "testCampaign" &&
                  //       log.status == "completed") ||
                  //     ((log.from == "triggerEmail" ||
                  //       log.from == "triggerSMS") &&
                  //       log.status == "completed")
                  //       ? "#baeebb"
                  //       : "",
                  // }}
                  key={index}
                >
                  <td>{indexOfFirstData + index + 1}</td>
                  <td>{log.from || log.type}</td>
                  <td>{log.latestTriggerDateTime}</td>
                  <td>{log._id || log.uuid || 0}</td>
                  <td>
                    {(log.from == "testCampaign" &&
                      log.status == "completed") ||
                    ((log.from == "triggerEmail" || log.from == "triggerSMS") &&
                      log.status == "completed")
                      ? log.totalSuccessCount
                      : "_"}
                  </td>
                  <td>
                    {(log.from == "testCampaign" &&
                      log.status == "completed") ||
                    ((log.from == "triggerEmail" || log.from == "triggerSMS") &&
                      log.status == "completed")
                      ? log.totalFailedCount
                      : "_"}
                  </td>
                  <td>
                    {" "}
                    {(log.from == "testCampaign" &&
                      log.status == "completed") ||
                    ((log.from == "triggerEmail" || log.from == "triggerSMS") &&
                      log.status == "completed")
                      ? log.totalTotalCount
                      : "_"}
                  </td>
                  <td
                    style={{
                      color:
                        log.status === "error"
                          ? "red"
                          : log.status === "completed"
                          ? "green"
                          : "",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      getDetailsOfCampaign(log._id);
                    }}
                  >
                    {log.status || ""}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>

        {/* Pagination Controls */}
        <div className="d-flex justify-content-between">
          <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
            Previous
          </Button>
          <span>Page {currentPage}</span>
          <Button
            onClick={handleNextPage}
            disabled={campaignJourney.length < dataPerPage}
          >
            Next
          </Button>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Campaign Journey Details</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.N</th>
                <th>Campaign Type</th>
                <th>Trigger Date(dd-mm-yy)</th>

                <th>UUID</th>
                <th>Sent {campaignType}</th>
                <th>Failed {campaignType}</th>
                <th>Total {campaignType} Count</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {modalData.length === 0 ? (
                <tr>
                  <td colSpan="6" className="text-center">
                    No data available
                  </td>
                </tr>
              ) : (
                modalData.map((log, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{log.from || log.type}</td>
                    <td>{log.triggerDateTime}</td>
                    <td>{log.uuid || log.uuid || 0}</td>
                    <td>
                      {log.from == "testCampaign" ||
                      ((log.from == "triggerEmail" ||
                        log.from == "triggerSMS") &&
                        log.status == "completed")
                        ? log.successCount
                        : "_"}
                    </td>
                    <td>
                      {log.from == "testCampaign" ||
                      ((log.from == "triggerEmail" ||
                        log.from == "triggerSMS") &&
                        log.status == "completed")
                        ? log.failedCount
                        : "_"}
                    </td>
                    <td>
                      {" "}
                      {log.from == "testCampaign" ||
                      ((log.from == "triggerEmail" ||
                        log.from == "triggerSMS") &&
                        log.status == "completed")
                        ? log.totalCount
                        : "_"}
                    </td>
                    <td
                      style={{
                        color:
                          log.status === "error"
                            ? "red"
                            : log.status === "completed"
                            ? "green"
                            : "",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        getDetailsOfCampaign(log._id);
                      }}
                    >
                      {log.from === "testCampaign" && log.successCount > 0
                        ? "completed"
                        : log.status}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default CampaignReportPage;
