import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import journeyLogs from "./../../../services/ campaignJourney/index";

export const getCampaignJourney = createAsyncThunk(
  "campaign-journey/fetch",
  async (body, thunkAPI) => {
    try {
      console.log("campaign journey --1", body);
      const result = await journeyLogs.getCampaignJourneyLogs(body);
      console.log("campaign journey---final", result);
      if (result && result.length > 0) {
        return result;
      } else {
        return [];
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
const initialState = { campaignsJourney: [] };

const campaignsSlice = createSlice({
  name: "campaignJourney",
  initialState,

  extraReducers: {
    [getCampaignJourney.fulfilled]: (state, action) => {
      console.log("campaignJourney.fulfilled", action);

      if (action.payload.length === 0) {
        state.campaignsJourney = []; // Ensure UI updates
      } else if (action.meta.arg.startLimit === 0) {
        state.campaignsJourney = action.payload; // Replace on first page
      } else {
        state.campaignsJourney = [...state.campaignsJourney, ...action.payload]; // Append for pagination
      }
    },
    [getCampaignJourney.rejected]: (state, action) => {
      state.campaignsJourney = null;
      //return { ...state, defaulters: [] };
    },
  },
});
export const getCampaignJourneyLogsDetails = createAsyncThunk(
  "campaign-journey/logs/fetch",
  async (body, thunkAPI) => {
    try {
      console.log("Fetching campaign journey:", body);

      const response = await journeyLogs.getCampaignJourneyLogsFullData(body);
      console.log("API Response:", response);

      if (!response || response.error) {
        throw new Error(
          response?.error || "Failed to fetch campaign journey logs"
        );
      }

      return response.length > 0 ? response : [];
    } catch (error) {
      console.error("Error in getCampaignJourneyLogsDetails:", error);
      thunkAPI.dispatch(
        setMessage(error.message || "Error fetching campaign journey logs")
      );
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  }
);

export default campaignsSlice.reducer;
